.app
{
	min-height: 60vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 1vmin);
	color: #333;
	text-align: center;
	background-color: #fff;
}

.app-header
{
	margin: 0 0 20px;

	&__logo
	{
		height: 20vmin;
		pointer-events: none;
	}
}

.restore-password
{
	&__title
	{

	}

	&__input
	{
		display: block;
		min-width: 300px;
		margin: 20px auto 0;
		padding: 10px 20px;
		border: 1px solid #ddd;
		text-align: center;
		outline: none;
		height: 44px;

		&:focus
		{
			border-color: #333;
		}
	}

	&__error
	{
		font-size: 10px;
		color: #ff3e3c;
	}

	&__button
	{
		display: block;
		min-width: 300px;
		margin: 20px auto 0;
		padding: 10px 20px;
		border: 0;
		background: #895589;
		color: #fff;
		cursor: pointer;
		opacity: 1;
		height: 44px;
		text-decoration: none;

		&:hover
		{
			background: darken(#895589, 5%);
		}
		
		&:disabled
		{
			cursor: not-allowed;
			background: #895589 !important;
			opacity: 0.7;
		}
	}
}

.restore-password-result
{
	&__title
	{

	}
}
